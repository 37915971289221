const Klaro = require("klaro");

const config = {
  elementID: "klaro",
  privacyPolicy: '/',
  groupByPurpose: true,
  storageMethod: 'cookie',
  cookieName: 'klaro',
  cookieExpiresAfterDays: 365,
  default: false,
  mustConsent: false,
  acceptAll: true,
  hideDeclineAll: false,
  hideLearnMore: false,
  lang: "de",
  translations: {
    de: {
      googleAnalytics: {
        description: 'Sammeln von Besucherstatistiken',
      },
      recaptcha: {
        description: 'Aussortieren von Spammern',
      },
      purechat: {
        description: 'Kontakt via Chat',
      },
      disqus: {
        description: 'Kommentare',
      },
      shariff: {
        description: 'Teilen von Seiten auf Sozialen Medien',
      },
      purposes: {
        analytics: 'Besucher-Statistiken',
        security: 'Sicherheit',
        livechat: 'Live Chat',
        advertising: 'Anzeigen von Werbung',
        styling: 'Styling',
        sharing: 'Teilen von Inhalten',
      },
    },
    en: {
      googleAnalytics: {
        description: 'User Statistics',
      },
      recaptcha: {
        description: 'Prevent Spam',
      },
      purechat: {
        description: 'Contact via Purechat',
      },
      disqus: {
        description: 'Comments via Disqus',
      },
      shariff: {
        description: 'Sharing of Pages to Social Media',
      },
      purposes: {
        analytics: 'Analytics',
        security: 'Security',
        livechat: 'Livechat',
        advertising: 'Advertising',
        styling: 'Styling',
        sharing: 'Sharing of Content',
      },
    }
  },
  services: [{
      name: "googleAnalytics",
      title: 'Google Analytics',
      purposes: ["analytics"],
    }
  ]
};

Klaro.setup(config);
window.klaro = Klaro;
window.klaroConfig = config;
